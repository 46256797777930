
import { Component, Vue } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import tasqsListModule from '@/store/modules/tasqsListModule';
import workflowModule from '@/store/modules/workflowModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    GroupAssetUpload: () => getView('GroupAssetUpload'),
  },
})
export default class Groups extends Vue {
   windowWidth = window.innerWidth;

   activeTableID: any = null;

    dataLoadingBackend = false;

   activeWorkspaceID: any = null;

   deleteGroupPopup = false;

   deleteGroupParams: any = {};

   availableSearchOptions: any = [];

   showUploadAssetPopup: any = false;

   newColumnName = '';

   newGroupName = '';

  showCreateGroupModal =false;

  searchDataLoading = false;

  searchResultWells: any =[]

  selectedWellNames: any = [];

  availableWellNames: any = [];

  tempGroups = []

  searchTerms: any = [];

  searchTermsJson: any = [];

  showModal = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = []

   showWellMenu = false;

   foundSearchResult = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   showSearchPopup = false;

   newCloumnType = '';

   columnDefs: any = [
     {
       field: 'wellName', pinned: 'left', sortable: true, filter: true, headerName: 'Well Name', width: 300, editable: true, checkboxSelection: true,
     },
     {
       field: 'Add Column +',
       sortable: true,
       pinned: 'right',
       headerComponent: 'agColumnHeader1',
       editable: false,

       initialWidth: 150,
       cellRendererFramework: 'cellRender',
       cellRendererParams: {
         clicked(field) {
           alert(`${field} was clicked`);
         },
       },
     },

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    get isMobile() {
      return this.windowWidth < 768;
    }

    deleteGroup(group) {
      this.deleteGroupPopup = true;
      this.deleteGroupParams = { group_id: group.ID };
    }

    async confirmDeleteGroup() {
      const params = this.deleteGroupParams;
      this.deleteGroupPopup = false;
      await scheduleModule.deleteGroup(params);
      await scheduleModule.getTableGroups({ table_id: this.activeTableID });
      this.deleteGroupParams = {};
    }

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    async searchByTermsNewTag(tag) {
    }

    async searchByTerms(tag) {
      this.searchDataLoading = true;
      this.foundSearchResult = false;
      this.searchResultWells = [];
      const searchQuery: any = [];

      this.searchTerms.push(tag);

      this.searchTerms.forEach((term, i) => {
        if (i !== 0) {
          searchQuery.push({ type: 'CONDITION_OPERATOR', value: 'and' });
        }
        searchQuery.push(term);
      });

      this.searchTerms.pop();

      if (searchQuery.length) {
        const stringifyData = JSON.stringify(searchQuery);
        // console.log(stringifyData);
        this.searchResultWells = await workflowModule.searchWellsByTerms(stringifyData);

        if (!this.searchResultWells.length) {
          this.foundSearchResult = true;
        }
      }
      this.searchDataLoading = false;
    }

    async searchByTermsRemoveTag(tag) {
      this.searchTerms = this.searchTerms.filter((result) => result.value !== tag.value);
      this.searchDataLoading = true;
      this.searchResultWells = [];
      const searchQuery: any = [];

      this.searchTerms.forEach((term, i) => {
        if (i !== 0) {
          searchQuery.push({ type: 'CONDITION_OPERATOR', value: 'and' });
        }
        searchQuery.push(term);
      });

      this.searchTerms.pop();

      if (searchQuery.length) {
        const stringifyData = JSON.stringify(searchQuery);
        // console.log(stringifyData);
        this.searchResultWells = await workflowModule.searchWellsByTerms(stringifyData);
        if (!this.searchResultWells.length) {
          this.foundSearchResult = true;
        }
      }
      this.searchDataLoading = false;
    }

    toggleSearchPopup() {
      this.showSearchPopup = !this.showSearchPopup;
    }

    get activeTableGroups() {
      return scheduleModule.activeTableGroups;
    }

    goToTasqPage(wellName) {
      const tasq = tasqsListModule.tasqList.find((t) => t.wellName === wellName);
      if (tasq && tasq.id) {
        tasqsListModule.setActiveTasq(tasq.id || '');
        tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
        this.showModal = true;
      } else {
        tasqsListModule.setKanbanSelectedTasq(wellName);
        assetsModule.setActiveProducingTasq(wellName);
        tasqsListModule.setActiveTasq('');
        this.showModal = true;
      }

    // tasqsListModule.setActiveTasq(tasq?.id || '');
    }

    createGroupFromUploadedSheet(data) {
      if (data && data.rowData) {
        this.selectedWellNames = data.rowData.map((row) => this.availableWellNames.find((w) => w.wellName === row.Well));

        this.selectedTags = data.tags;
        this.showCreateGroupModal = true;
      }
    }

    createGroupFromSelectedWells() {
      // console.log(this.searchResultWells.filter((result) => result.selected));
      this.selectedWellNames = this.searchResultWells.filter((result) => result.selected).map((well) => well.wellName);
      this.showCreateGroupModal = true;
    }

    async goToDetailPage(group) {
      console.log(group);
      const groupDetail = await scheduleModule.getTableGroup({ group_id: group.ID });
      this.$router.push({ name: 'GroupDetail', params: { id: group.ID } });
      console.log(groupDetail);
	  // @ts-ignore
      scheduleModule.setActiveGroup(groupDetail);
    }

    async createGroup() {
      this.dataLoadingBackend = true;
      // ts-ignore
      const rowData = this.selectedWellNames.map((well) => ({ Well: well.wellName, Tags: this.selectedTags }));
      this.selectedWellNames.forEach(async (name) => {
        const wellTags = await scheduleModule.getTagsForWell({ node_id: name });
        let newtags = this.selectedTags.concat(wellTags.map((t) => JSON.parse(t)));
        newtags = newtags.map((t) => t.toLowerCase());
        // console.log(newtags);
        await scheduleModule.addTagsToWell({ node_id: name, tags: newtags });
      });

      const columnData = [
        {
          ColumnName: 'Well',
          ColumnType: 'WELL',
          CustomName: 'Well Name',
        },

        {
          ColumnName: 'Tags',
          ColumnType: 'TAGS',
          CustomName: 'Tags',
        },
      ];

      const metaData = {
        tags: this.selectedTags,
        wellCount: rowData.length,
      };

      const groupObj:any = {
        username: accountModule.user.email.toLowerCase(),
        workspace_id: this.activeWorkspaceID,
        table_id: this.activeTableID,
        columns: JSON.stringify(columnData),
        payload: JSON.stringify(rowData),
        metadata: JSON.stringify(metaData),
        group_name: this.newGroupName,
        usage: 'ASSETS',
      };

      await scheduleModule.postNewTableGroupPage(groupObj);

      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;

      await scheduleModule.getTableGroups({ table_id: this.activeTableID, excludePayload: true });
      this.dataLoadingBackend = false;
    }

    async createGroupUsingImportedData() {
      this.dataLoadingBackend = true;
      const rowData = this.selectedWellNames.map((name) => ({ Well: name, Tags: this.selectedTags }));
      // this.selectedWellNames.forEach(async (name) => {
      //   const wellTags = await scheduleModule.getTagsForWell({ node_id: name });
      //   let newtags = this.selectedTags.concat(wellTags.map((t) => JSON.parse(t)));
      //   newtags = newtags.map((t) => t.toLowerCase());
      //   // console.log(newtags);
      //   await scheduleModule.addTagsToWell({ node_id: name, tags: newtags });
      // });

      const columnData = [
        {
          ColumnName: 'Well',
          ColumnType: 'WELL',
          CustomName: 'Well Name',
        },

        {
          ColumnName: 'Tags',
          ColumnType: 'TAGS',
          CustomName: 'Tags',
        },
      ];

       const metaData = {
        tags: this.selectedTags,
        wellCount: rowData.length,
      };

      const groupObj:any = {
        username: accountModule.user.email.toLowerCase(),
        workspace_id: this.activeWorkspaceID,
        table_id: this.activeTableID,
        columns: JSON.stringify(columnData),
        payload: JSON.stringify(rowData),
        group_name: this.newGroupName,
        metadata: metaData,
        usage: 'ASSETS',
      };

      await scheduleModule.postNewTableGroupPage(groupObj);

      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;

      await scheduleModule.getTableGroups({ table_id: this.activeTableID });
      this.dataLoadingBackend = false;
    }

    get myView() {
      return this.windowWidth < 768 ? 'tasqs-mobile' : 'tasqs-desktop';
    }

    beforeMount() {

    }

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get availableUsers() {
      return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
        text: i.name,
        value: i.email,
        type: 'USER_SEARCH',
      })).sort((a, b) => a.text.localeCompare(b.text));
    }

    get enabledWells() {
      return assetsModule.enabledWells;
    }

    async created() {
      this.dataLoadingBackend = true;

      workspaceModule.setActivePage('Groups');
      scheduleModule.setActivePage('Groups');
      this.context = { componentParent: this };
      // if(tasqsListModule.activePage !== "Groups"){
      tasqsListModule.setActivePage('Groups');

      // }
      await workspaceModule.getWorkspaces();

      // console.log(workspaceModule.activeWorkspace);
      const workspace: any = workspaceModule.activeWorkspace;
      if (workspace?.Tables.length < 1) {
        await scheduleModule.postNewWorkspaceTable({
		  username: accountModule.user.email.toLowerCase(),
		  // @ts-ignore
		  workspace_id: workspaceModule.activeWorkspace.ID,
		  table_title: 'Asset Table',
	  });
      } else {
        this.activeTableID = workspace?.Tables[0].ID;
        this.activeWorkspaceID = workspace?.Tables[0].WorkspaceID;
      }

      if (this.activeTableID) {
        await scheduleModule.getTableGroups({ table_id: this.activeTableID, excludePayload: true });

        const promises: any = [];
        await this.sleep(2000);
        promises.push(tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM));
        promises.push(await accountModule.getReassignmentList({ useExisting: true }));
        promises.push(assetsModule.getEnabledWells());
        Promise.all(promises).then(async () => {
          console.log('resolved');
          const getAllWellTags = await scheduleModule.getTagsForWell({ node_id: null });
          // console.log(getAllWellTags)
          scheduleModule.setAllTags(getAllWellTags);
          // console.log(scheduleModule.availableTags)
          // const groups = (scheduleModule.activeTableGroups);
          // let payload = [];
          let tags: any = [];
          const allWellTags = scheduleModule.availableTags;
          // console.log(allWellTags)
          if (allWellTags.length) {
            tags = tags.concat(allWellTags);
          }

          if (tags && tags.length) {
            this.availableTags = [...new Set(tags)].sort();
          }
          this.availableWellNames = assetsModule.enabledWells;

          if (this.availableTags) {
            let tagsObjectForSearch = this.availableTags.map((t) => ({ type: 'TAG_SEARCH', value: t.toLowerCase(), text: t.toLowerCase() }));
            // console.log(this.availableUsers);
            tagsObjectForSearch = tagsObjectForSearch.concat(this.availableUsers);
            // console.log(tagsObjectForSearch);
            this.availableSearchOptions = tagsObjectForSearch;
          }
        }, (err) => {
          console.log('Error:');
          console.log(err);
        });

        this.dataLoadingBackend = false;
      }
    }

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      workspaceModule.setActivePage(null);
      scheduleModule.setActivePage(null);
      window.removeEventListener('resize', this.onResize);
    }

    async closeDialog() {
      this.showModal = false;
      await this.getNewTags();
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }

    async getNewTags() {
      if (this.activeTableID) {
        await scheduleModule.getTableGroups({ table_id: this.activeTableID });
        const getAllWellTags = await scheduleModule.getTagsForWell({ node_id: null });
        // console.log(getAllWellTags)
        scheduleModule.setAllTags(getAllWellTags);
        // console.log(scheduleModule.availableTags)
        // const groups = (scheduleModule.activeTableGroups);
        // let payload = [];
        let tags: any = [];
        const allWellTags = scheduleModule.availableTags;
        // console.log(allWellTags)
        if (allWellTags.length) {
          tags = tags.concat(allWellTags);
        }

        if (tags && tags.length) {
          this.availableTags = [...new Set(tags)].sort();
        }
        this.availableWellNames = assetsModule.enabledWells;

        if (this.availableTags) {
          let tagsObjectForSearch = this.availableTags.map((t) => ({ type: 'TAG_SEARCH', value: t.toLowerCase(), text: t.toLowerCase() }));
          console.log(this.availableUsers);
          tagsObjectForSearch = tagsObjectForSearch.concat(this.availableUsers);
          console.log(tagsObjectForSearch);
          this.availableSearchOptions = tagsObjectForSearch;
        }
      }
    }
}

